import { createTheme, ThemeProvider } from "@mui/material";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { Layout } from "./components/layout";
import { AuthProvider, AccountProvider } from "./contexts";
import { ForgotPassword } from "./pages/ForgotPassword";
import { Home } from "./pages/Home";
import { SignIn } from "./pages/SignIn";
import { SignUp } from "./pages/SignUp";
import { UserProfile } from "./pages/UserProfile";

const theme = createTheme({
  typography: {
    body1: {
      fontSize: "14px",
    },
    subtitle2: {
      fontFamily: "Roboto, Helvetica, Arial, sans-serif",
      fontWeight: "700",
    },
  },
  palette: {
    primary: {
      light: "#64caf4",
      main: "#1194bc",
    },
    // success: {
    //   main: "#00c4b7",
    // },
  },
});

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <AuthProvider>
          <AccountProvider>
            <Routes>
              <Route path="/" element={<Layout />}>
                <Route index element={<Home />} />
                <Route path="profile" element={<UserProfile />} />
              </Route>
              <Route path="sign-in" element={<SignIn />} />
              <Route path="sign-up" element={<SignUp />} />
              <Route path="forgot-password" element={<ForgotPassword />} />
            </Routes>
          </AccountProvider>
        </AuthProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
