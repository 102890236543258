import { resetPassword } from "@aws-amplify/auth";
import { zodResolver } from "@hookform/resolvers/zod";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Alert from "@mui/material/Alert";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid2";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { Dispatch, SetStateAction, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";

const formSchema = z.object({
  email: z.string(),
});

type FormInputs = z.infer<typeof formSchema>;

export const ForgotPasswordForm = (props: {
  nextStep: () => void;
  setUserEmail: Dispatch<SetStateAction<string>>;
}) => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormInputs>({
    resolver: zodResolver(formSchema),
  });
  const [errorCode, setErrorCode] = useState<string | undefined>();

  const onSubmit: SubmitHandler<FormInputs> = ({ email }) => {
    props.setUserEmail(email);
    resetPassword({
      username: email,
    })
      .then(() => props.nextStep())
      .catch(() => setErrorCode("internalError"));
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1 }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {t("security.texts.forgotPassword")}
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid size={{ xs: 12 }}>
              <TextField
                fullWidth
                label={t("security.fields.email")}
                autoComplete="email"
                error={Boolean(errors.email?.message)}
                helperText={
                  errors.email?.message
                    ? t(`security.errors.${errors.email?.message}`)
                    : null
                }
                required={true}
                {...register("email")}
              />
            </Grid>
            <Grid size={{ xs: 12 }}>
              <Button type="submit" fullWidth variant="contained">
                {t("security.actions.reset")}
              </Button>
            </Grid>
            <Grid size={{ xs: 12 }} direction="column" width="100%">
              <Grid container justifyContent="right">
                <Link href="/sign-in" variant="body2">
                  {t("security.texts.alreadyAccount")}
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </form>
        {errorCode ? (
          <Alert severity="error">{t(`security.errors.${errorCode}`)}</Alert>
        ) : null}
      </Box>
    </Container>
  );
};
