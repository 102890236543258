import AddCircleIcon from "@mui/icons-material/AddCircle";
import {
  Box,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Fragment, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { AccountContext } from "../../contexts";
import { RecurrentService } from "../../services";
import { RecurrentRow } from "./recurrent-row";
import { RecurrentAdd } from "./recurrent-add";

export const RecurrentsList = () => {
  const accountContext = useContext(AccountContext);
  const recurrentService = new RecurrentService(accountContext);
  const { t } = useTranslation();

  const { accountId } = accountContext.state;
  const [isLoading, setIsLoading] = useState(true);
  const [addRecurrent, setAddRecurrent] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    recurrentService.emptyRecurrents();
    if (accountId) {
      recurrentService
        .listRecurrents(accountId)
        .finally(() => setIsLoading(false));
    }
  }, [accountId]);

  if (isLoading || accountId === null) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", padding: 3 }}>
        <CircularProgress />
      </Box>
    );
  }

  const onAddClick = () => {
    setAddRecurrent(true);
  };

  const onCloseClick = () => {
    setAddRecurrent(false);
  };

  return (
    <Fragment>
      <RecurrentAdd
        accountId={accountId}
        open={addRecurrent}
        handleClose={onCloseClick}
      />
      <TableContainer
        component={Box}
        sx={{
          maxHeight: { xs: 440, lg: 800 },
          scrollbarWidth: "none",
        }}
      >
        <Table stickyHeader aria-label="sticky table" size="small">
          <TableHead>
            <TableRow>
              <TableCell
                align="left"
                sx={{
                  typography: "subtitle2",
                }}
              >
                {t("recurrents.fields.nextTransfer")}
              </TableCell>
              <TableCell align="left">
                <Typography variant="subtitle2">
                  {t("recurrents.fields.name")}
                </Typography>
              </TableCell>
              <TableCell
                align="right"
                sx={{
                  typography: "subtitle2",
                }}
              >
                {t("recurrents.fields.amount")}
              </TableCell>
              <TableCell
                align="right"
                sx={{
                  typography: "subtitle2",
                }}
              >
                <IconButton onClick={() => onAddClick()}>
                  <AddCircleIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {accountContext.state.recurrents.map((recurrent) => (
              <RecurrentRow key={recurrent.id} recurrent={recurrent} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Fragment>
  );
};
